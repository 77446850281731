
import './App.css';
import Footer from './components/Components';
import Aboutus from './pages/Aboutus';
import Contectus from './pages/Contectus';
import Details from './pages/Details';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Service from './pages/Service';
import Gallery1 from './pages/Gallery';
import Details1 from './pages/Details1';
import Details3 from './pages/Details3';
import Detail4 from './pages/detail4';
import ERROR from './components/ERROR';
function App() {
  return (
    <div className='App'>

  
    <BrowserRouter  > 
      
    <Routes>

   <Route path='/' element={<Home/>}></Route>
   
   <Route path='/farmhouse-karjat' element={<Aboutus/>}></Route>
   
   <Route path='/family-friendly-farm-stays-karjat' element={<Contectus/>}></Route>
   <Route path='/villa-near-karjat' element={<Gallery1/>}></Route>
   <Route path='/best-villa-karjat' element={<Details/>}></Route>
   <Route path='/rental-bamboo-cottages-karjat' element={<Details1/>}></Route>
   <Route path='/cottages-rooms-karjat' element={<Details3/>}></Route>
   <Route path='/best-farmhouse-karjat' element={<Service/>}></Route>
   <Route path='/rental-cottages-karjat' element={<Detail4/>}></Route>
   <Route path="/*" element={<ERROR/>}></Route>
   </Routes>
</BrowserRouter>
</div>
  );
}

export default App;
