import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import farm1 from "../Asset/ICONAB/FARM.JPG"
import villa2 from "../Asset/Farmimages/vila2.JPG"
import villa3 from "../Asset/Farmimages/villa3.JPG"
import cilla from "../Asset/Farmimages/u-rivergate-karjat-best-image.jpg"
import newbam from "../Asset/Resort/newbambo.JPG"
import newcota from "../Asset/Resort/newcotage.JPG"
import newlot from "../Asset/Resort/newlotous.JPG"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';




// import required modules
import {   } from 'swiper/modules';

const Slider = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[ ]}
        className="mySwiper"
      >
        <SwiperSlide><div className="content-box paddingclass"
         style={{  backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${newbam})`,backgroundSize:"100% 100%"}}>
  <span
    className="sub-title fadeInUp animated"
    data-animation-in="fadeInUp"
    data-delay-in="0.1"
    style={{ opacity: 1, animationDelay: "0.1s", color:"white" }}
  >
    Unique Place And Farm
  </span>
  <h1
    data-animation-in="fadeInUp"
    data-delay-in="0.3"
    className="fadeInUp animated fontofslid"
    style={{ opacity: 1, animationDelay: "0.3s",color:"white" }}
  >
   Madhura Farm <br />
   Tranquil Retreat in Nature 
  </h1>
  
</div>
</SwiperSlide>
<SwiperSlide><div className="content-box paddingclass"
         style={{  backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${newcota})`,backgroundSize:"100% 100%"}}>
  <span
    className="sub-title fadeInUp animated"
    data-animation-in="fadeInUp"
    data-delay-in="0.1"
    style={{ opacity: 1, animationDelay: "0.1s", color:"white" }}
  >
     Unique Place And Farm
  </span>
  <h1
    data-animation-in="fadeInUp"
    data-delay-in="0.3"
    className="fadeInUp animated fontofslid"
    style={{ opacity: 1, animationDelay: "0.3s",color:"white" }}
  >
   Madhura Farm <br />
  Rustic Bliss Awaits You
  </h1>
  
</div>
</SwiperSlide>
<SwiperSlide><div className="content-box paddingclass"
         style={{  backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${newlot})` ,backgroundSize:"100% 100%"}}>
  <span
    className="sub-title fadeInUp animated"
    data-animation-in="fadeInUp"
    data-delay-in="0.1"
    style={{ opacity: 1, animationDelay: "0.1s", color:"white" }}
  >
        Unique Place And Farm
  </span>
  <h1
  
    data-animation-in="fadeInUp"
    data-delay-in="0.3"
    className="fadeInUp animated fontofslid"
    style={{ opacity: 1, animationDelay: "0.3s",color:"white" }}
  >
    Discover Harmony
    <br/> at Madhura Farm
  </h1>
 
</div>
</SwiperSlide>
     


     
        
      </Swiper>
    </>
  );
}
export default Slider
