import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import logo from "../Asset/logo.png"
// Import Swiper styles
import 'swiper/css';




// import required modules


export default function Testimonial() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        // centeredSlides={true}
       
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
       
    
        className="my"
      >
        <SwiperSlide> <div className="client-block ">
        <div
          className="inner-box wow fadeInLeft"
          style={{ visibility: "visible" }}
        >
          <h4 className="title">seema Bane</h4>
          
          <div className="image-box">
            <figure
              className="image wow fadeInUp"
              style={{ visibility: "visible" }}
            >
              <img src={logo} style={{width:"40%"}} alt="" />
            </figure>
            <i className="icon fa fa-circle-check" />
          </div>
          <div className="text" style={{textAlign:""}}>
          Amazing food. PERIOD. Service is always perfect, food is always on point, and the staff is very friendly and accommodating. Always looking forward to this place with my friends,
Nursery It was very well maintained place. If you are fond of natural beauty, then do visit it, it will be great refreshing place.Good!

          </div>
        </div>
      </div></SwiperSlide>
        <SwiperSlide><div className="client-block ">
        <div
          className="inner-box wow fadeInLeft"
          data-wow-delay="200ms"
          style={{ visibility: "visible" }}
        >
          <h4 className="title">JAYAWANT SAWANT</h4>
          
          <div className="image-box">
            <figure
              className="image wow fadeInUp"
              style={{ visibility: "visible" }}
            >
              <img src={logo} style={{width:"40%"}}  alt="" />
            </figure>
            <i className="icon fa fa-circle-check" />
          </div>
          <div className="text" style={{textAlign:""}}>
          We enjoyed stay at Madhura farmhouse, Food was also delicious. Room cleaning and service is also good.
          </div>
        </div>
      </div></SwiperSlide>
        <SwiperSlide>   <div className="client-block ">
        <div
          className="inner-box wow fadeInLeft"
          data-wow-delay="300ms"
          style={{ visibility: "visible" }}
        >
          <h4 className="title">sarita guhagarkar</h4>
          
          <div className="image-box">
            <figure
              className="image wow fadeInUp"
              style={{ visibility: "visible" }}
            >
              <img src={logo} style={{width:"40%"}} alt="" />
            </figure>
            <i className="icon fa fa-circle-check" />
          </div>
          <div className="text" style={{textAlign:""}}>
          A really good farmhouse for a staycation. Best place for relaxation and quality time. Great staff with great service and good accomodation. Cleanliness is been taken care of. Highly recommended to anyone who wants to spend their weekend out of town but still close and easy to locate.
          </div>
        </div>
      </div></SwiperSlide>
        
      </Swiper>
    </>
  );
}
