import farnming from "../Asset/Farmimages/dining.jpg"
import d2 from "../Asset/Resort/Bamboo Cottage.jpg"
import d3 from "../Asset/Farmimages/dining.jpg"
import d4 from "../Asset/Farmimages/POOL.JPG"
import d5 from "../Asset/Resort/onewbambo.JPG"
import d6 from "../Asset/Resort/onewcotage.JPG"
import d7 from "../Asset/Resort/onewlotous.JPG"
import g1 from "../Asset/Gallery/g1.JPG"
import g2 from "../Asset/Gallery/g2.JPG"
import g3 from "../Asset/Gallery/g3.JPG"
import g4 from "../Asset/Gallery/g4.JPG"
import g5 from "../Asset/Gallery/g5.JPG"
import g6 from "../Asset/Gallery/g6.JPG"
import g7 from "../Asset/Gallery/g7.JPG"
import g8 from "../Asset/Gallery/g8.JPG"
import g9 from "../Asset/Gallery/g9.JPG"
import g10 from "../Asset/Gallery/g10.JPG"
import g11 from "../Asset/Gallery/g11.JPG"
import g12 from "../Asset/Gallery/g12.JPG"
import g13 from "../Asset/Gallery/g13.JPG"
import g14 from "../Asset/Gallery/g14.JPG"
import g15 from "../Asset/Gallery/g15.JPG"
import g16 from "../Asset/Gallery/g16.JPG"
import g17 from "../Asset/Gallery/g17.JPG"
import g18 from "../Asset/Gallery/g18.JPG"
import g19 from "../Asset/Gallery/g19.JPG"
import g20 from "../Asset/Gallery/g20.JPG"
import g21 from "../Asset/Gallery/g21.JPG"
import g22 from "../Asset/Gallery/g22.JPG"
export const photos = [
    {
      src: farnming,
      width: 4,
      height: 3
    },
    {
      src: d2,
      width: 1,
      height: 1
    },
  
    {
      src:d4,
      width: 3,
      height: 4
    },
    {
      src: d5,
      width: 3,
      height: 4
    },
    {
      src: d6,
      width: 4,
      height: 3
    },
    {
      src: d7,
      width: 3,
      height: 4
    },
    {
      src: g1,
      width: 3,
      height: 4
    },
    {
      src: g2,
      width: 3,
      height: 4
    },
    {
      src: g3,
      width: 3,
      height: 4
    },
    {
      src: g4,
      width: 3,
      height: 4
    },
    {
      src: g5,
      width: 3,
      height: 4
    },
    {
      src: g6,
      width: 3,
      height: 4
    },
    {
      src: g7,
      width: 3,
      height: 4
    },
    {
      src: g8,
      width: 3,
      height: 4
    },
    {
      src: g9,
      width: 3,
      height: 4
    },
    {
      src: g10,
      width: 3,
      height: 4
    },
    {
      src: g11,
      width: 3,
      height: 4
    },
    {
      src: g12,
      width: 3,
      height: 4
    },
    {
      src: g13,
      width: 3,
      height: 4
    },
    {
      src: g14,
      width: 3,
      height: 4
    },
    {
      src: g15,
      width: 3,
      height: 4
    },
    {
      src: g16,
      width: 3,
      height: 4
    },
    {
      src: g17,
      width: 3,
      height: 4
    },
    {
      src: g18,
      width: 3,
      height: 4
    },
    {
      src: g19,
      width: 3,
      height: 4
    },
    {
      src: g20,
      width: 3,
      height: 4
    },
    {
      src: g21,
      width: 3,
      height: 4
    },
    {
      src: g22,
      width: 3,
      height: 4
    },

   
  ];
  