import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Gallery1 from '../pages/Gallery';
import "../css/Navbar.css"
import logo from "../Asset/logo.png"
const Hadder = () => {
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" variant="dark">
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"   style={{backgroundColor:"black"}}/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link to='/'>Home</Link>
                            <Link to='/farmhouse-karjat'>Aboutus</Link>
                            <Link to='/best-farmhouse-karjat'>Service</Link>
                        </Nav>
                        <Navbar.Brand>
                            <Link to="/"> 
                                <div className='mid-logo'>
                                    <div className='LogoImage'>
                                        <img src={logo} alt="Logo" />
                                    </div>
                                </div>
                            </Link>
                        </Navbar.Brand>
                        <Nav className="me-auto">   <NavDropdown title="Accomodation" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">
              <Link to='/cottages-rooms-karjat'>Cottage</Link>
                </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">

              <Link to='/best-villa-karjat'>Lotus Villa</Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
              <Link to='/rental-bamboo-cottages-karjat'>Bamboo Cottage</Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">
              <Link to='/rental-cottages-karjat'>Cottage Sonchafa </Link>
                </NavDropdown.Item>
            
            </NavDropdown>
                            <Link to='/villa-near-karjat'>Gallery</Link>
                            <Link to='/family-friendly-farm-stays-karjat'>Contact Us</Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div className='SmallSLogo'>
                        <div className='mid-logo'>
                            <div className='LogoImage'>
                                <img src={logo} alt="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </Navbar>
    </div>
  )
}

export default Hadder
