import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import room from "../Asset/Resort/newbambo.JPG"
import room2 from "../Asset/Resort/newlotous.JPG"
import room3 from "../Asset/Resort/newcotage.JPG"
import new1 from "../Asset/Resort/new.jpeg"
const Cotage = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        // centeredSlides={true}
       
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
       
    
        className="my"
      >
        <SwiperSlide>
        <div className="room-block ">
        <div
          className="inner-box wow fadeIn animated"
          data-wow-delay="300ms"
          style={{
            visibility: "visible",
            animationDelay: "300ms",
            animationName: "fadeIn"
          }}
        >
          <div className="image-box">
            <figure className="image-2 overlay-anim">
              <img src={room} alt="" />
            </figure>
          </div>
          <div className="content-box">
            <h6 className="title">
              <a href="#">Bamboo Cottage</a>
            </h6>
           
          </div>
          <div className="box-caption">
            <a href="#" title="" className="book-btn" style={{color:"white"}}>
            <Link to="/rental-bamboo-cottages-karjat" style={{color:"white"}}>
            book now
            </Link>  
            </a>
            <ul className="bx-links">
              <li>
                <a href="#" title="">
                  <i className="fa fa-wifi" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-bed" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-bath" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-shower" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="room-block ">
        <div
          className="inner-box wow fadeIn animated"
          data-wow-delay="500ms"
          style={{
            visibility: "visible",
            animationDelay: "500ms",
            animationName: "fadeIn"
          }}
        >
          <div className="image-box">
            <figure className="image-2 overlay-anim">
              <img src={room3} alt="" />
            </figure>
          </div>
          <div className="content-box">
            <h6 className="title">
              <a href="#"> Cottage Room</a>
            </h6>
            
          </div>
          <div className="box-caption">
            <a href="#" title="" className="book-btn">
             <Link to="/cottages-rooms-karjat" style={{color:"white"}}>book now</Link> 
            </a>
            <ul className="bx-links">
              <li>
                <a href="#" title="">
                  <i className="fa fa-wifi" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-bed" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-bath" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-shower" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </SwiperSlide>
      
      <SwiperSlide>
      <div className="room-block ">
        <div
          className="inner-box large-style wow fadeIn animated"
          data-wow-delay="400ms"
          style={{
            visibility: "visible",
            animationDelay: "400ms",
            animationName: "fadeIn"
          }}
        >
          <div className="image-box">
            <figure className="image-2 overlay-anim">
              <img src={room2} alt="" />
            </figure>
          </div>
          <div className="content-box">
            <h6 className="title">
              <a href="#">Lotus Villa</a>
            </h6>
         
          </div>
          <div className="box-caption">
            <a href="#" title="" className="book-btn">
             <Link to="/best-villa-karjat" style={{color:"white"}}> book now</Link> 
            </a>
            <ul className="bx-links">
              <li>
                <a href="#" title="">
                  <i className="fa fa-wifi" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-bed" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-bath" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-shower" />
                </a>
              </li>
            </ul>
          </div>
        </div>
</div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="room-block ">
        <div
          className="inner-box large-style wow fadeIn animated"
          data-wow-delay="400ms"
          style={{
            visibility: "visible",
            animationDelay: "400ms",
            animationName: "fadeIn"
          }}
        >
          <div className="image-box">
            <figure className="image-2 overlay-anim">
              <img src={new1} alt="" />
            </figure>
          </div>
          <div className="content-box">
            <h6 className="title">
              <a href="#">Cottage Sonchafa </a>
            </h6>
         
          </div>
          <div className="box-caption">
            <a href="#" title="" className="book-btn">
             <Link to="/rental-cottages-karjat" style={{color:"white"}}> book now</Link> 
            </a>
            <ul className="bx-links">
              <li>
                <a href="#" title="">
                  <i className="fa fa-wifi" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-bed" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-bath" />
                </a>
              </li>
              <li>
                <a href="#" title="">
                  <i className="fa fa-shower" />
                </a>
              </li>
            </ul>
          </div>
        </div>
</div>
      </SwiperSlide> 
      
      </Swiper>
    </>
  )
}

export default Cotage
