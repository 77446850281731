import React from 'react'
import logo from "../Asset/logo.png"
import email from "../Asset/ICONAB/email.png"
import pin from "../Asset/ICONAB/pin.png"
import phone from "../Asset/ICONAB//phone-call.png"
import rightarrow from "../Asset/icon/cfh/next.png"
import facebook from  "../Asset/icon/cfh/facebook-logo.png"
import instagrm from "../Asset/icon/cfh/instagram.png"
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div>
      <footer className="main-footer footer-style-one">
  {/* Widgets Section */}
  <div className="widgets-section">
    <div className="auto-container">
      <div className="row">
        {/* Footer Column */}
        <div className="footer-column col-lg-4 col-sm-6">
          <div
            className="footer-widget about-widget wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <div className="widget-content">
              <div className="logo">
                <a href="#">
                  {" "}
                  <img src={logo} alt="" title="" style={{display:"flex", width:"40%"}} />
                </a>
              </div>
              <div className="text" style={{textAlign:"left"}}>
              Indulge in the unique charm of our natural villa,<br></br> where the soothing sounds of rustling leaves and the gentle flow of water create a perfect harmony. 
              </div>
              <ul className="social-icon-two">
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100072122541496">
                    <img src={facebook} style={{width:"20px" ,paddingBottom:"7px"}} className="fab fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/madhurafarmsagrotourism/">
                    <img src={instagrm} style={{width:"20px"  ,paddingBottom:"7px"}} className="fab fa-twitter" />
                  </a>
                </li>
               
              </ul>
            </div>
          </div>
        </div>
        {/* Footer Column */}
        <div className="footer-column col-lg-2 col-sm-6 mb-0">
          <div
            className="footer-widget links-widget wow fadeInLeft animated"
            data-wow-delay="200ms"
            style={{
              visibility: "visible",
              animationDelay: "200ms",
              animationName: "fadeInLeft"
            }}
          >
            <h4 className="widget-title" style={{textAlign:"left"}}>Services Links</h4>
            <div className="widget-content">
              <ul className="user-links">
                <li style={{textAlign:"left"}}>
                  <a href="#">
                    <img src={rightarrow} style={{width:"10px"}} className="fa-solid fa-crosshairs" />
                    <Link to='/'>Home</Link>
                  </a>
                </li>
                <li style={{textAlign:"left"}}>
                  <a href="#">
                    <img src={rightarrow} style={{width:"10px"}} className="fa-solid fa-crosshairs" />
                    <Link to='/farmhouse-karjat'>Aboutus</Link>
                  </a>
                </li>
                <li style={{textAlign:"left"}}>
                  <a href="#">
                    <img src={rightarrow} style={{width:"10px"}} className="fa-solid fa-crosshairs" />
                    <Link to='/best-farmhouse-karjat'>Service</Link>
                  </a>
                </li>
                <li style={{textAlign:"left"}}>
                  <a href="#">
                    <img src={rightarrow} style={{width:"10px"}} className="fa-solid fa-crosshairs" />
                    <Link to='/villa-near-karjat'>Gallery</Link>
                  </a>
                </li>
                <li style={{textAlign:"left"}}>
                  <a href="#">
                    <img src={rightarrow} style={{width:"10px"}} className="fa-solid fa-crosshairs" />
                    <Link to='/family-friendly-farm-stays-karjat'>Contact Us</Link>
                  </a>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
        {/* Footer Column */}
        <div className="footer-column col-lg-3 col-sm-6">
          <div
            className="footer-widget info-widget wow fadeInLeft animated"
            data-wow-delay="300ms"
            style={{
              visibility: "visible",
              animationDelay: "300ms",
              animationName: "fadeInLeft"
            }}
          >
            <h4 className="widget-title" style={{textAlign:"left"}}>Information</h4>
            <div className="widget-content">
              {/* Recent Post */}
              <article className="recent-post" style={{textAlign:"left"}}>
                <div className="inner">
                  <div className="post-info" >
                    <img src={phone} style={{width:"20px"}} className="fas fa-phone" />: (+91) 8419944990
                  </div>
                  
                  <div className="post-info">
                    <img src={email} style={{width:"20px"}} className="fa fa-envelope" />:madhurafarms1@gmail.com
                  </div>
                  <div className="post-info">
                    <img src={pin} style={{width:"20px"}} className="fas fa-location" />: Kushivali Rajnala Road, Village: Mothe Vengaon,
Tal: Karjat, Dist.: Raigad-410201 (Maharashtra)
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        {/* footer column */}
        <div className="footer-column col-lg-3 col-sm-6">
          <div
            className="footer-widget news-widget wow fadeInLeft animated"
            data-wow-delay="400ms"
            style={{
              visibility: "visible",
              animationDelay: "400ms",
              animationName: "fadeInLeft"
            }}
          >
            <h4 className="widget-title" style={{textAlign:"left"}}>Location</h4>

            <div className="text">
              <section className="map-section">
              <iframe
              
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3774.6575853090303!2d73.36407597495096!3d18.902265857404156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7fb640053582d%3A0x4ff26cbbbd94122b!2sMADHURA%20FARMS!5e0!3m2!1sen!2sin!4v1700120824514!5m2!1sen!2sin"
  // width={600}
  height={200}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>
</section>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*  Footer Bottom */}
  <div
    className="footer-bottom wow slideInUp animated"
    style={{ visibility: "visible", animationName: "slideInUp" }}
  >
    <div className="auto-container">
      <div className="inner-container">
        <div className="copyright-text">
        Copyright © 2023, All Right Reserved  <a href="https://skdm.in/" >
      Shree Krishna Digital
    </a>
        </div>
        
         
       
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer
