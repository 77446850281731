import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../components/photos";
import Hadder from "../components/Hadderrafce";
import Footer from '../components/Components'
import sds from "../Asset/sds.png"
import { MetaTags } from 'react-meta-tags';
import hadderimage from "../Asset/Farmimages/listing-page-karjat.15d450a3.jpg"
const Gallery1 = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
  
  return (
    <div>
      <MetaTags>
        <title>Villa near Karjat | Madhura Farm</title>
        <meta title=" Villa near Karjat | Madhura Farm" />
        <meta name="description" content="Escape to serenity with our charming Villa near  Karjat. Experience luxury and tranquility in a picturesque setting. Book your stay !!" />
        <meta name="keywords" content="farmhouse-karjat,best-farmhouse-karjat,cottages-rooms-karjat,best-villa-karjat,rental-bamboo-cottages-karjat,villa-near-karjat,family-friendly-farm-stays-karjat" />
        <link rel="canonical" href="https://madhurafarms.com/villa-near-karjat" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Villa near Karjat | Madhura Farm" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://madhurafarms.com/" />
        <meta property="og:description" content="Escape to serenity with our charming Villa near  Karjat. Experience luxury and tranquility in a picturesque setting. Book your stay !!" />
        <meta property="og:image" content="https://madhurafarms.com/static/media/logo.a70dbf41e465615a4172.png" />
      </MetaTags>
      <Hadder/>
      <section
  className="page-title"
  style={{backgroundImage:`url(${hadderimage})` }}
>
  <div className="auto-container">
    <div className="title-outer text-center">
      <h1 className="title">Villa near Karjat</h1>
      <ul className="page-breadcrumb">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>Gallery</li>
      </ul>
    </div>
  </div>
</section>
       <div style={{paddingTop:"30px"}}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
    <p className="container">
Escape to a serene retreat by considering a farmhouse in Karjat for your next getaway. This idyllic destination, known for its picturesque landscapes and tranquil atmosphere, offers a perfect setting for a rejuvenating experience. Imagine a villa near Karjat surrounded by lush greenery, providing a haven away from the hustle and bustle of city life.

A Farmhouse in Karjat is your ticket to a blissful escape. Whether you're seeking a weekend retreat or a longer vacation, this scenic location promises a memorable experience. The Villa near Karjat is strategically located to offer breathtaking views and easy access to the region's natural wonders. The farmhouse in Karjat is designed to provide a harmonious blend of modern amenities and rustic charm, ensuring a comfortable and authentic stay.

Picture yourself waking up to the sounds of nature in your villa near Karjat. The farmhouse in Karjat allows you to unwind amidst the beauty of the surroundings. Explore the lush gardens, take a dip in the private pool, or simply relax on the veranda with a book. The Villa near Karjat caters to your every need, ensuring a seamless fusion of luxury and nature.

A Farmhouse in Karjat is not just accommodation; it's an experience. The villa near Karjat provides an ideal base for exploring the nearby attractions, such as Kondana Caves, Peth Fort, and Bhivpuri Waterfall. After a day of exploration, return to your farmhouse in Karjat for a cozy evening by the fireplace or a barbecue under the starlit sky.

In conclusion, a Villa near Karjat is your gateway to a magical retreat. Immerse yourself in the charm of this farmhouse in Karjat, where every moment is a celebration of nature and luxury. Plan your escape today and indulge in the serenity that awaits you at your chosen villa near Karjat.</p>
    <Footer/>
  
    </div>
  )
}

export default Gallery1
