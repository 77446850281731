import React,{useState} from 'react'
import "../css/Servic.css"
import Hadder from '../components/Hadderrafce'
import sds from "../Asset/sds.png"
import bad from "../Asset/icon/bed.png"
import breakfast from "../Asset/icon/breakfast.png"
import farm  from "../Asset/icon/farm.png"
import parking from "../Asset/icon/parking-area.png"
import swimingpool from "../Asset/icon/swimming-pool.png"
import wifi from "../Asset/icon/wifi.png"
import Footer from '../components/Components'
import hadderimage from "../Asset/Farmimages/listing-page-karjat.15d450a3.jpg"
import { MetaTags } from 'react-meta-tags';
import Enquiryform from '../components/Enqueryform'
import gener from "../Asset/gener.png"
const Service = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
       <MetaTags>
        <title>Best Farmhouse in Karjat | Madhura Farm</title>
        <meta title=" Best Farmhouse in Karjat | Madhura Farm" />
        <meta name="description" content="Experience the epitome of relaxation at the Best Farmhouse in Karjat. Immerse yourself in luxury and tranquility amidst scenic landscapes." />
        <meta name="keywords" content="farmhouse-karjat,best-farmhouse-karjat,cottages-rooms-karjat,best-villa-karjat,rental-bamboo-cottages-karjat,villa-near-karjat,family-friendly-farm-stays-karjat" />
        <link rel="canonical" href="https://madhurafarms.com/best-farmhouse-karjat" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Farmhouse in Karjat | Madhura Farm" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://madhurafarms.com/" />
        <meta property="og:description" content="Experience the epitome of relaxation at the Best Farmhouse in Karjat. Immerse yourself in luxury and tranquility amidst scenic landscapes." />
        <meta property="og:image" content="https://madhurafarms.com/static/media/logo.a70dbf41e465615a4172.png" />
      </MetaTags>
       <Hadder/>
      
             <section
  className="page-title"
  style={{backgroundImage:`url(${hadderimage})` }}
>
  <div className="auto-container">
    <div className="title-outer text-center">
      <h1 className="title">Best Farmhouse in Karjat</h1>
      <ul className="page-breadcrumb">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>Service</li>
      </ul>
    </div>
  </div>
</section>
<section id="service-details2" className="pt-120 pb-90 p-relative">
  <div className="animations-01">
    {/* <img src="https://htmldemo.zcubethemes.com/riorelax/img/bg/an-img-01.png" alt="an-img-01" /> */}
  </div>
  <div className="container">
    <div className="row align-items-center">
    <div className="col-lg-4 col-md-6">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={bad} style={{width:"100px"}}  alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={bad} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Large Bedroom</a>
            </h3>
            <p>
            Unwind in a Generously Sized Bedroom, Offering Comfort and Ample Space for Relaxation and Rest.
            </p>
            <a href="#" style={{color:"#388d3b", fontWeight:"500"}}  onClick={() => setModalShow(true)}>
              Enquiry Now <i className="fal fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={breakfast} style={{width:"100px"}}  alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={breakfast} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Food And Breakfast</a>
            </h3>
            <p>
            Savor Scrumptious Food and Wake Up to a Deliciously Energizing Breakfast Spread.
            </p>
            <a href="#" style={{color:"#388d3b", fontWeight:"500"}}  onClick={() => setModalShow(true)}>
              Enquiry Now <i className="fal fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={farm} style={{width:"100px"}}  alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={farm} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#" > Nursery</a>
            </h3>
            <p>
              
Lush greenery, vibrant blooms, and expert care at our nursery – cultivating natural beauty with precision and passion.
            </p>
            <a href="#" style={{color:"#388d3b", fontWeight:"500"}}  onClick={() => setModalShow(true)}>
              Enquiry Now <i className="fal fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={gener} style={{width:"100px"}}  alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={gener} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#" >Generator</a>
            </h3>
            <p>
            ensures power in emergencies, keeping you safe and secure effortlessly.
            </p>
            <a href="#" style={{color:"#388d3b", fontWeight:"500"}}  onClick={() => setModalShow(true)}>
              Enquiry Now <i className="fal fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={swimingpool} style={{width:"100px"}}  alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={swimingpool} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#"> Swimming Pool </a>
            </h3>
            <p>
            Dive into refreshing waters, bask in the sun, and make a splash in our invigorating swimming pool oasis.
            </p>
            <a href="#" style={{color:"#388d3b", fontWeight:"500"}}  onClick={() => setModalShow(true)}>
              Enquiry Now <i className="fal fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={wifi} style={{width:"100px"}} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={wifi} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">
WIFI</a>
            </h3>
            <p>
            Enjoy seamless browsing with our reliable and fast Wi-Fi, enhancing your digital experience during your stay.
            </p>
            <a href="#" style={{color:"#388d3b", fontWeight:"500"}}  onClick={() => setModalShow(true)}>
              Enquiry Now <i className="fal fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<p className='container'>

Nestled in the picturesque landscapes of Karjat, the Best Farmhouse in Karjat stands as a testament to tranquility and luxury. This idyllic retreat is an epitome of rustic charm and modern comfort, making it the perfect destination for those seeking a peaceful escape from the hustle and bustle of city life.

The Best Farmhouse in Karjat offers a unique blend of traditional architecture and contemporary amenities. Surrounded by lush greenery and panoramic views of the Western Ghats, this farmhouse provides an immersive experience in nature's embrace. The serene ambiance is complemented by the meticulously designed interiors that reflect a harmonious fusion of rural aesthetics and urban sophistication.

Each corner of the Best Farmhouse in Karjat exudes warmth and hospitality, creating an inviting atmosphere for guests. The spacious and well-appointed rooms offer a comfortable retreat, while the outdoor spaces, adorned with manicured gardens, beckon visitors to unwind and rejuvenate. Whether you are seeking a romantic getaway, a family retreat, or a gathering of friends, this farmhouse caters to diverse preferences.

One of the standout features of the Best Farmhouse in Karjat is its state-of-the-art amenities. Guests can indulge in a refreshing swim in the sparkling pool, take leisurely strolls through the orchards, or simply relax in the cozy lounge areas. The farmhouse is also equipped with modern kitchen facilities, allowing guests to savor delectable meals prepared with locally sourced ingredients.

Moreover, the Best Farmhouse in Karjat is strategically located, providing easy access to popular attractions in the region. Whether you wish to explore the mesmerizing Kondana Caves or embark on a trek in the verdant hills, the farmhouse serves as an ideal base for your adventures.

In conclusion, the Best Farmhouse in Karjat offers a harmonious blend of natural beauty, comfort, and convenience. With its captivating surroundings and impeccable amenities, this farmhouse is more than just accommodation; it's an experience that lingers in the hearts of those fortunate enough to visit. Immerse yourself in the charm of Karjat and create lasting memories at the Best Farmhouse in Karjat.
</p>
<Enquiryform show={modalShow} onHide={() => setModalShow(false)} />
<Footer/>

    </div>
  )
}

export default Service
